body {
  min-height: 100vh;
  text-align: center;
  color: #ffebcd;
  background-image: url("vert.a4ae4c5d.gif");
  font-family: Courier New, Courier, monospace;
  font-size: 18px;
  font-weight: bold;
}

body > * {
  width: 65%;
  margin: auto;
}

header {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #191970cc;
  border-radius: 10px;
  margin-top: 50px;
  padding: 5px;
}

.align {
  justify-content: center;
  align-items: center;
  display: flex;
}

main {
  background-color: #16162be6;
  border: solid #c71585;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  display: flex;
}

main * {
  margin: 20px 0;
}

h1 {
  color: #ffebcd;
  padding: 5px;
}

a:link {
  color: violet;
}

a:visited {
  color: #9370db;
}

a:hover {
  color: #0ff;
}

ul {
  list-style-image: url("tinysparklestar.8f11d920.gif");
}

#bar {
  background-color: #c71585;
  border: solid #c71585;
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
}

#bar p {
  text-align: right;
  color: #ffebcd;
  margin: 0 5px;
  font-size: large;
}

footer {
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  background-color: #191970cc;
  border-radius: 10px;
  margin-top: 20px;
  padding: 5px 5px 5px 20px;
}

.margin {
  margin-bottom: 50px;
}

/*# sourceMappingURL=index.6ade8eae.css.map */
